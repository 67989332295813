import axios from "axios";

const axiosInstance = axios.create({
	timeout: 3000
});

axiosInstance.interceptors.request.use((config) => {
	if (localStorage.getItem('token')) {
		config.headers.Authorization = localStorage.getItem('token');
	}
	return config;
}, (err) => {
	return Promise.reject(err);
});

axiosInstance.interceptors.response.use((response) => {
	return response.data;
}, (error) => {
	return Promise.reject(error);
});

export const get = (url, params={}) => {
	return axiosInstance.get(url, {params: params});
}

export const post = (url, params={}, config={}) => {
	return axiosInstance.post(url, params, config);
}
