import { get, post } from "@/utils/request";

export const sliceUpload = (formdata, config = {}) => {
  return post("file/upload", formdata, config);
};

export const mergeSlice = (hash) => {
  return post("file/merge", { hash: hash });
};

// 显示涨粉榜列表
export const shwoRanking = (params) => {
  return get("ranking/index", params);
};

// 显示涨粉榜分类
export const shwoCategory = (params) => {
  return get("ranking/category", params);
};

// 播主详情-数据概览 播主信息
export const showAuthorInfo = (id) => {
  return get("ranking/author?userid=" + id);
};

// 播主详情-数据概览 数据概览
export const showRankingDetail = (id) => {
  return get("ranking/detail?userid=" + id);
};

// 播主详情-数据概览 粉丝、点赞、评论趋势
export const showRankingTrend = (id) => {
  return get("ranking/trend?userid=" + id);
};

// 播主详情-数据概览 近10个作品
export const showTopTen = (id) => {
  return get("ranking/product?userid=" + id);
};

// 播主详情-数据概览 手动刷新播主基础信息
export const getUpdateInfo = (id) => {
  return get("ranking/refresh?userid=" + id);
};

// 播主详情-数据概览 粉丝分析
export const showFansData=(id)=>{
	return get('ranking/fan?userid='+id)
}


export const chanmamaDataAnalyseGetGoodsCategory = () => {
	return get('chanmama_data_analyse/getGoodsCategory')
}

export const chanmamaDataAnalyseGoodsRank = (params) => {
	return get('chanmama_data_analyse/goodsRank', params)
}

export const chanmamaDataAnalyseGetGoodsHead = (params) => {
	return get('chanmama_data_analyse/getGoodsHead', params)
}

export const chanmamaDataAnalyseReviewGoodsHead = (params) => {
	return get('chanmama_data_analyse/reviewGoodsHead', params)
}

export const chanmamaDataAnalyseGoodsHeat = (params) => {
	return get('chanmama_data_analyse/goodsHeat', params)
}

export const chanmamaDataAnalyseDouyinVideoExtend = (params) => {
	return get('chanmama_data_analyse/douyinVideoExtend', params)
}
export const importStuTmpl = (formdata) => {
    return post('user/import', formdata)
}
export const chanmamaGoodsOfficialGetGoodsCategory = () => {
    return get("chanmama_goods_official/getGoodsCategory");
};
export const chanmamaGoodsOfficialGoodsRank = (params) => {
    return get("chanmama_goods_official/goodsRank", params);
};

