<template>
    <div class="dy-products-rank">
        <div class="dy-header">
            <el-radio-group v-model="day_type" size="medium" class="rank-type" @change="selectRankType" style="margin-top: 10px">
                <el-radio-button label="day">日榜</el-radio-button>
                <el-radio-button label="week">周榜</el-radio-button>
                <el-radio-button label="month">月榜</el-radio-button>
            </el-radio-group>
            <el-date-picker size="medium" @change="selectDay" v-if="day_type === 'day'" v-model="dayValue" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                            :picker-options="pickerOptions" class="rank-date" :clearable="false" style="margin-top: 10px"></el-date-picker>
            <el-date-picker size="medium" @change="selectWeek" v-if="day_type === 'week'" v-model="weekValue" type="week" format="yyyy 第 WW 周" placeholder="选择周"
                            :picker-options="pickerOptions" value-format="yyyy-MM-dd" class="rank-date" :clearable="false" style="margin-top: 10px"></el-date-picker>
            <el-date-picker size="medium" @change="selectMonth" v-if="day_type === 'month'" v-model="monthValue" type="month" placeholder="选择月"
                            value-format="yyyyMM" :picker-options="pickerOptionsMonth" class="rank-date" :clearable="false" style="margin-top: 10px"></el-date-picker>

            <div style="margin-top: 10px">
                商品来源
                <el-select v-model="platform" placeholder="请选择" style="margin: 0 20px 0 10px" @change="toggleGoodFrom">
                    <el-option v-for="item in platformList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div style="margin-top: 10px">
                佣金比例
                <el-select v-model="commission_rate" placeholder="请选择" style="margin-left: 10px" @change="toggleCommission">
                    <el-option v-for="item in commissionRateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
        </div>
        <div class="category-list">
            <div class="cate-left">分类：</div>
            <div class="cate-content">
                <template v-for="(cateItem, cateIndex) in categoryList">
                    <div v-if="cateItem.sub_categories.length === 0" class="cate-item"
                         @click="chooseFirst(cateItem)"
                         :class="{current: big_category === cateItem.cat_name}"
                         :key="`categoryList_${cateIndex}`">{{cateItem.cat_name}}</div>
                    <el-popover v-else placement="bottom" width="200" trigger="hover">
                        <div class="cate-item" :class="{current: big_category === cateItem.cat_name}" slot="reference" :key="`categoryList_${cateIndex}`">{{cateItem.cat_name}}</div>
                        <div class="cate-children">
                            <div class="child" :class="{current: first_category === childItem.cat_name + '&' + cateItem.cat_name}" v-for="(childItem, childIndex) in cateItem.sub_categories"
                                 @click="chooseFirst(cateItem, childItem)"
                                 :key="`cateChildren_${cateIndex}_${childIndex}`">{{childItem.cat_name}}</div>
                        </div>
                    </el-popover>
                </template>
            </div>
        </div>
        <el-table style="width: 100%; margin-top: 10px;min-height: calc(100vh - 258px);" class="rank-table" :data="rankTableData" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333'}" :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column align="center" prop="rank" label="排行" width="50"></el-table-column>
            <el-table-column label="商品" align="left" min-width="200">
                <template slot-scope="scope">
                    <div class="good-info">
                        <img :src="scope.row.image" alt="">
                        <div class="good-right">
                            <div class="goods-name text-overflow" :title="scope.row.title">{{scope.row.title}}</div>
                            <span class="num">
                                &yen;{{scope.row.coupon_price}}
                                <span style="text-decoration: line-through; font-weight: 400; color: #999">&yen;{{scope.row.market_price}}</span>
                            </span>
                        </div>
                        <i class="iconfont">&#xe7c0;</i>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="佣金比例">
                <template slot-scope="scope">
                    {{scope.row.rate}}%
                </template>
            </el-table-column>
            <el-table-column align="center" label="销量">
                <template slot-scope="scope">
                    {{formatW(scope.row.order_count)}}件
                </template>
            </el-table-column>

            <el-table-column align="center" prop="amount" label="销售额">
                <template slot-scope="scope">
                    {{formatW(scope.row.amount)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="30天转化率">
                <template slot-scope="scope">
                    <span v-if="scope.row.month_conversion_rate > 0">{{scope.row.month_conversion_rate}}%</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="toDetail(scope.row)" size="small" circle type="danger">
                        <i class="iconfont">&#xec66;</i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="showMore" style="text-align: center; line-height: 60px; color: #999; cursor: pointer" @click="moreBtn">+点击加载更多</div>
        <div v-else style="text-align: center; line-height: 60px; color: #999; cursor: pointer">没有更多了</div>
    </div>
</template>

<script>
    import * as dayjs from 'dayjs'
    import { chanmamaGoodsOfficialGetGoodsCategory, chanmamaGoodsOfficialGoodsRank } from '@/utils/apis.js'

    export default {
        name: "DyProductsRank",
        data() {
            return {
                day_type: 'day', // 排行榜类型
                pickerOptions: {
                    disabledDate(time) {
                        // return time.getTime() > Date.now();
                        return time.getTime() >= (Date.now() - 8.64e7);
                    },
                },
                pickerOptionsMonth: {
                    disabledDate(time) {
                        const date = new Date();
                        const year = date.getFullYear();
                        let month = date.getMonth() + 1;
                        if (month >= 1 && month <= 9) {
                            month = "0" + month;
                        }
                        const currentdate = year.toString() + month.toString();
                        const timeyear = time.getFullYear();
                        let timemonth = time.getMonth() + 1;
                        if (timemonth >= 1 && timemonth <= 9) {
                            timemonth = "0" + timemonth;
                        }
                        const timedate = timeyear.toString() + timemonth.toString();
                        return currentdate <= timedate;
                    },
                },
                date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),

                //选择日期
                dayValue: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
                //选择周
                weekValue: '',
                //选择月
                monthValue: '',

                // 商品来源
                platformList: [
                    {
                        value: '',
                        label: '不限'
                    },
                    {
                        value: 'taobao',
                        label: '淘宝'
                    },
                    {
                        value: 'tmall',
                        label: '天猫'
                    },
                    {
                        value: 'jinritemai',
                        label: '抖音小店'
                    },
                    {
                        value: 'jd',
                        label: '京东'
                    },
                    {
                        value: 'kaola',
                        label: '考拉海购'
                    },
                    {
                        value: 'suning',
                        label: '苏宁'
                    },
                    {
                        value: 'weipinhui',
                        label: '唯品会'
                    },
                    {
                        value: 'yanxuan',
                        label: '严选'
                    },
                ],
                platform: '',

                // 佣金比例
                commissionRateList: [
                    {
                        value: '',
                        label: '不限'
                    },
                    {
                        value: '10-',
                        label: '>=10%'
                    },
                    {
                        value: '20-',
                        label: '>=20%'
                    },
                    {
                        value: '30-',
                        label: '>=30%'
                    },
                    {
                        value: '40-',
                        label: '>=40%'
                    },
                    {
                        value: '50-',
                        label: '>=50%'
                    },
                    {
                        value: '60-',
                        label: '>=60%'
                    },
                ],
                commission_rate: '',

                //排行商品列表
                rankTableData: [],
                currentPageNum: 1, // 当前分页
                big_category: '全部', // 一级分类筛选
                first_category: '', // 二级分类筛选
                categoryList: [], // 商品分类
                showMore: false,
            }
        },
        mounted() {
            this.getList()
            this.getGoodCategory()
        },
        methods: {
            formatW(val) {
                if (Number(val) > 10000) {
                    return Number(val / 10000).toFixed(2) + 'w';
                }
                return val;
            },
            // 获取列表
            getList() {
                let params = {
                    big_category: this.big_category && this.big_category !== '全部' ? this.big_category : '',
                    first_category: this.first_category ? this.first_category.split('&')[0] : '',
                    second_category: '',
                    platform: this.platform,
                    page: this.currentPageNum,
                    size: 50,
                    commission_rate: this.commission_rate,
                    date: this.date,
                    day_type: this.day_type,
                    sort: '',
                }
                chanmamaGoodsOfficialGoodsRank(params).then(res => {
                    if (res.code === 200) {
                        console.log('res', res);
                        let listTmp = this.rankTableData
                        listTmp = [...listTmp, ...res.data]
                        this.rankTableData = listTmp
                        if (res.data.length > 0) {
                            this.showMore = true
                        } else {
                            this.showMore = false
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },
            // 加载更多
            moreBtn() {
                this.currentPageNum++
                this.getList();
            },
            // 筛选商品来源
            toggleGoodFrom(val) {
                this.currentPageNum = 1
                this.rankTableData = []
                this.getList()
            },
            // 筛选佣金比例
            toggleCommission(val) {
                this.currentPageNum = 1
                this.rankTableData = []
                this.getList()
            },
            // 选择一级二级分类
            chooseFirst(first, second) {
                this.currentPageNum = 1
                this.rankTableData = []
                this.big_category = first.cat_name
                if (second) {
                    this.first_category = second.cat_name + '&' + first.cat_name
                } else {
                    this.first_category = ''
                }
                this.getList()
            },
            // 获取商品分类
            getGoodCategory() {
                chanmamaGoodsOfficialGetGoodsCategory().then((res) => {
                    if (res.code === 200) {
                        let item = {
                            id: -1,
                            cat_name: '全部',
                            sub_categories: [],
                        }
                        res.data.unshift(item)
                        this.categoryList = res.data
                    } else {
                        this.$message.warning(res.message)
                    }
                }).catch(err => {})
            },
            //选择日榜，周榜，月榜
            selectRankType(val) {
                if (val === 'day') {
                    this.dayValue = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
                    this.date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
                }
                if (val === 'week') {
                    this.weekValue = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
                    let weekStart = dayjs().startOf('week').subtract(6, 'day').format('YYYYMMDD')
                    let weekEnd = dayjs().endOf('week').subtract(6, 'day').format('YYYYMMDD')

                    this.date = weekStart + '-' + weekEnd
                }
                if (val === 'month') {
                    this.monthValue = dayjs().subtract(1, 'month').format('YYYYMM')
                    this.date = dayjs().subtract(1, 'month').format('YYYYMM')
                }
                this.currentPageNum = 1
                this.rankTableData = []
                this.getList();
            },
            // 选择日期
            selectDay(val) {
                this.dayValue = val
                this.date = val

                this.currentPageNum = 1
                this.rankTableData = []
                this.getList();
            },
            // 选择周榜
            selectWeek(val) {
                this.weekValue = val
                let weekStart = dayjs(val).startOf('week').add(1, 'day').format('YYYYMMDD')
                let weekEnd = dayjs(val).endOf('week').add(1, 'day').format('YYYYMMDD')
                this.date = weekStart + '-' + weekEnd

                this.currentPageNum = 1
                this.rankTableData = []
                this.getList();
            },
            // 选择月榜
            selectMonth(val) {
                this.monthValue = val
                this.date = val

                this.currentPageNum = 1
                this.rankTableData = []
                this.getList();
            },
            // 去详情页
            toDetail(item) {
                this.$router.push({
                    path: '/student/dy/goodDetail',
                    query: {
                        id: item.promotion_id,
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    .dy-products-rank {
        width: 100%;
        background-color: #fff;
        padding: 10px 20px 10px;
        box-sizing: border-box;
        .dy-header {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .rank-type {
                margin-right: 20px;
            }
            .rank-date {
                margin-right: 20px;
            }
            .search-input {
                width: 300px;
            }
        }
    }

    .category-list {
        display: flex;
        margin-top: 20px;
        .cate-left {

        }
        .cate-content {
            width: 1%;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            .cate-item {
                padding: 0 8px;
                margin: 0 0 10px 12px;
                border: 1px solid transparent;
                cursor: pointer;
                &.current {
                    border-color: #1E33E3;
                    color: #1E33E3;
                }
            }
        }
    }
    .cate-children {
        .child {
            cursor: pointer;
            line-height: 36px;
            padding-left: 10px;
            &:hover {
                background: #f5f7fa;
            }
            &.current {
                color: #1E33E3;
            }
        }
    }
    .good-info {
        display: flex;
        align-items: center;
        img {
            width: 46px;
            height: 46px;
        }
        .good-right {
            width: 1%;
            flex: 1;
            margin: 0 10px;
            .num {
                font-weight: 700;
            }
        }
        .iconfont {
            color: #ff0000;
            font-size: 20px;
        }
    }
</style>